import React from "react"
import loadable from "@loadable/component"
import { graphql } from "gatsby"

const Layout = loadable(() => import("@common/Layout/Layout"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))
const Fintech = loadable(() => import("@components/Fintech"))

const FintechPage = ({ path, data }) => {
  return (
    <>
      <SEO
        title="Transforming Fintech: Unleashing Innovation with our Expert Fintech Software Development Company"
        titleTemplate="Impressit"
        SEOData={data.allStrapiSeoDescriptions?.nodes}
        description="Power your fintech business with our expert software development. Custom solutions for growth, user experience, and streamlined operations"
        isNotIndexed={false}
        ownMeta={{
            name: "googlebot",
            content: "all"
        }}
        path={path}
      />
      <Layout path={path} isNoPadding>
        <Fintech />
      </Layout>
    </>
  )
}
export default FintechPage

export const fintechPageQuery = graphql`
    query fintechPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
